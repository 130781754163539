.sticky {
  position: sticky;
  top: 0;
  z-index: 2000;
}

.react-calendar-timeline .rct-dateHeader-primary {
  border: 0px !important;
}

.react-calendar-timeline .rct-header-root {
  background: #2e3192;
  border-top-left-radius: 10px;
  color: #eeeeee;
}

.react-calendar-timeline .rct-calendar-header {
  width: 100% !important;
  max-height: 50px;
}

.react-calendar-timeline .rct-dateHeader {
  font-family: sf pro text regular;
  font-size: 14px;
  font-weight: bold;
  background: #2e3192;
  color: #eeeeee;
  border: 0px;
  border-top: 1px solid #5053b4;
  height: 20px;
}

#root>div>div>div.Board_box__fYHRC>div>div.react-calendar-timeline>div.rct-header-root>div.rct-calendar-header>div:nth-child(1) {
  height: 20px !important;
}

#root>div>div>div.Board_box__fYHRC>div>div.react-calendar-timeline>div.rct-header-root>div.rct-calendar-header>div:nth-child(1)>div:nth-child(2) {
  height: 20px !important;
}

#root>div>div>div.Board_box__fYHRC>div>div.react-calendar-timeline>div.rct-header-root>div.rct-calendar-header>div:nth-child(2) {
  height: 20px !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  font-family: sf pro text regular;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even:nth-child(30) {
  border-bottom-left-radius: 10px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  font-family: sf pro text regular;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #efefef99;
  border-bottom: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-vertical-lines .rct-hl-odd {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-vertical-lines .rct-hl-even {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  /* background: #ffffffff; */
  border: 1px solid #ededed;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: #f7f7f700;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: #efefef99;
  border: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.react-calendar-timeline .rct-sidebar {
  max-width: 110px;
}

.rct-scroll {
  width: 100% !important;
}

#root>div>div.Calendar_box__dCgiy>div>div.react-calendar-timeline>div.rct-outer>div.rct-scroll {
  width: 100% !important;
}

#root>div>div.Calendar_box__dCgiy>div>div.react-calendar-timeline>div.rct-outer>div.rct-sidebar>div {
  max-width: 130px;
}

#root>div>div.Calendar_box__dCgiy>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  max-width: 130px;
}

#root>div>div.Board_box__fYHRC>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  max-width: 130px;
}

#root>div>div>div.Board_box__fYHRC>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  width: 140px !important;
}

#root>div>div.Board_box__Y-dq9>div>div:nth-child(2)>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  width: 120px !important;
}

#root>div>div.Board_box__4K4SB>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  max-width: 110px;
}

#root>div>div.Board_box__JN1O8>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  max-width: 110px;
}

#root>div>div.Board_box__Y-dq9>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
  width: 126px !important;
}

@media screen and (max-width: 500px) {
  #root>div>div.Board_box__4K4SB>div>div.react-calendar-timeline>div.rct-header-root>div:nth-child(1) {
    max-width: 64px;
  }
}

@media screen and (max-width: 500px) {
  .react-calendar-timeline .rct-sidebar {
    max-width: 64px;
  }
}